.header{
    display: flex;
    justify-content: space-around;
    /* position: absolute; */
    position: relative;
    /* left: 0rem; */
    right: 9rem;
    top: 1rem;
    display: inline-flex;
    z-index: 100;

}

.logo{
    width: 10rem;
    height: 3rem;
}

.header-menu{
    display: flex;
    list-style: none;
    gap: 2rem;
    color: white;
    text-decoration: none;
}
.header-menu>li:hover{
    cursor: pointer;
    color: var(--orange);
}
.link-1, .link-2, .link-3, .link-4, .link-5{
    text-decoration: none;
    color: white;
}
.link-1:hover, .link-2:hover, .link-3:hover, .link-4:hover, .link-5:hover{
    color: var(--orange);
}

@media screen and (max-width:768px){
    .header>:nth-child(2){
        /* position: fixed;
        right: 2rem; */
        position: relative;
        right: -11rem;
        z-index: 99;
    }
    .header-menu{
        /* flex-direction: column;
        padding: 2rem; */
        position: absolute;
        top: 1rem;
        right: 0rem;
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;

    }
    .logo{
        /* position: fixed; */
        left: 2rem;
        position: relative;
        left: 6rem;
    }
}

@media screen and (max-width: 368px) {
    .logo{
        left: 8rem;
    }
}